.text-xs {
  font-size: 80%;
}

.text-s {
  font-size: 90%;
}
.hr-line {
  width: 100%;
  border: 1px solid #f1f1f1;
}

/* Home page sections */
.intro-section {
  background-image: var(--intro-background);
  padding: 2rem;
}
.features-section {
  background: #f16334;
  padding-top: 4rem;
  padding-bottom: 0;
  color: #ffffff;
}
.features-section h1 {
  font-size: 180%;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}
.features-section p {
  color: #ffe400;
}
.feature-section-img {
  width: 95%;
  margin: 0 auto;
}
.registration-section {
  margin: 5rem auto;
}

.registration-section h1 {
  font-size: 180%;
  font-weight: bold;
  color: #f16334;
  text-align: center;
}
.registration-checkbox {
  --size: 12px;
}
.registration-radio {
  height: 18px;
  width: 18px;
}
.privacy-section {
  font-size: 90%;
  line-height: 1.2rem;
}
.login-modal .modal-wrapper {
  padding: 0;
  min-height: 400px;
  height: auto;
  margin: 0 1rem;
}
.login-reset-link {
  color: #f16334;
  text-decoration: underline;
  font-size: 85%;
  cursor: pointer;
  text-align: center;
}

.subscribe-modal .modal-wrapper {
  --max-width: 80%;
}

.intro-highlight {
  font-weight: bold;
  font-size: 200%;
  color: #ffffff;
  padding: 0.75rem;
  margin: 0.5rem 0;
  background: #f16334;
}
/* Header Styles */
.header {
  background-color: #f16334;
  padding: 1rem;
  color: #ffffff;
}

.header-nav {
  text-align: right;
  font-size: 90%;
  color: #ffffff;
  text-decoration: none;
}
.header-nav-a {
  text-decoration: none;
  color: #ffffff;
}
.header-nav-a:hover {
  text-decoration: underline;
}
.header-nav-a:visited {
  color: #ffffff;
}

/* Footer Styles */
.footer {
  background-color: #f16334;
  padding: 0.75rem;
  text-align: center;
  color: #ffffff;
  font-size: 80%;
  font-weight: 400;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
}

/* Search Container */

.modality-switch {
  font-size: 50%;
}
.search-container {
  background-color: #f16334;
  padding: 2rem;
  padding-top: 0;
  color: #ffffff;
  border-bottom: #e3e3e3 1px solid;
}
.search-button {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 800;
  text-align: center;
  line-height: 1.5;
  color: #f16334;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-empty-container {
  min-height: 200px;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.search-button:hover {
  cursor: pointer;
}
.search-status {
  background-color: #f1f1f1;
}
.search-status-area {
  color: #666666;
  --background: #f1f1f1;
  font-size: 90%;
  text-align: center;
}

.chat-container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  width: 75px;
  height: 75px;
  --border-radius: 80%;
}
.chat-icon {
  font-size: 300%;
}
/* messaging */
.message-header {
  width: 100%;
}
.message-empty-container {
  min-height: 200px;
  width: 80%;
  margin: 5rem auto;
  text-align: center;
}
.message-empty-image {
  min-height: 200px;
  width: 50%;
  margin: 5px auto;
  padding: 0;
  text-align: center;
}

.message-divider {
  margin-bottom: 2rem;
}
.message-divider p {
  width: 100%;
  font-size: 80%;
  text-align: center;
  color: var(--ion-color-primary);
}
.message-box {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
  --border-radius: 80%;
}

.message-right-pane {
  border-left: 1px solid #f1f1f1;
  margin-bottom: 25vh;
}

.message-content-area {
  min-height: 100vh;
}

.message-text-area-input {
  display: block;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.no-shadow {
  --box-shadow: none;
}
.message-send-but {
  margin-top: 12px;
  --box-shadow: none;
}
/* Tutor Details */

.tutor-details {
  text-align: left;
  font-weight: 300;
}

.tutor-profile-img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 2rem auto;
  overflow: hidden;
  background: #f3f3f3;
}

/* Tutor profile image in details page with white border */
.tutor-details-profile-img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  position: relative;
  margin: 1rem auto;
  overflow: hidden;
  background: #f3f3f3;
  border: 2px var(--ion-color-primary) solid;
}

.tutor-profile-img-edit {
  width: 121px;
  height: 125px;
  position: absolute;
  text-align: center;
  top: 90px;
  padding: 0.3rem 0;
  opacity: 0.8;
  z-index: 9999;
  background: #f16334;
}
.tutor-img-upload {
  --max-width: 80%;
  --max-height: 40%;
}
.tutor-img-upload-wrapper {
  padding: 2rem;
}
.tutor-input-container {
  margin: 2rem 0;
}
.tutor-input-wrapper {
  background-color: #f1f1f1;
  padding: 1rem 1rem;
}

.tutor-subject-list {
  height: auto;
}
.avatar-img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  margin: 1rem auto;
  overflow: hidden;
  background: #f3f3f3;
  border: 2px var(--ion-color-primary) solid;
}

/* Subscriptions */
.subscription-active {
  background: #d1e7dd;
  border-left: 4px solid #479f76;
  border-radius: 2px 4px 4px 2px;
  color: #0f5132;
  font-size: 14px;
  line-height: 1.8;
  margin: 0.75rem 0;
  padding: 1rem;
}
.subscription-inactive {
  background: rgba(255, 250, 237, 0.8);
  border-left: 4px solid rgb(255, 204, 95);
  border-radius: 2px 4px 4px 2px;
  color: rgb(115, 101, 69);
  font-size: 14px;
  line-height: 1.8;
  margin: 0.75rem 0;
  padding: 1rem;
}

.subscriptions {
  --background: #f5f7fa;
}
/* General Styles */

.receiverChat {
  background: #cccccc;
  border-radius: 2px 4px 4px 2px;
  color: rgb(115, 101, 69);
  font-size: 14px;
  float: left;
  clear: both;
  max-width: 80%;
  line-height: 1.5;
  margin: 0.25rem 0;
  padding: 1rem 2rem;
}
.senderChat {
  background: #f1f1f1;
  border-radius: 2px 4px 4px 2px;
  color: rgb(115, 101, 69);
  font-size: 14px;
  line-height: 1.5;
  max-width: 80%;
  float: right;
  clear: both;
  margin: 0.25rem 0;
  padding: 1rem 2rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-options {
  --min-width: 80%;
  overflow-y: scroll;
}

ion-slide.swiper-slide {
  min-height: 100%;
  align-items: flex-start;
}

.alert-error {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 85%;
  width: 100%;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border: 1px solid #badbcc;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 85%;
  width: 100%;
}

.signup-modal .modal-wrapper {
  padding: 0;
  min-height: 400px;
  height: auto;
  margin: 0 1rem;
}

.signup-modal .content {
  padding: 5%;
  text-align: center;
}
